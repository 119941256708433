.wrap {
    width: 50%;
    background-color: rgb(93, 0, 0);
    border-radius: 4vh;
    margin: 3vh;
    line-height: 5vh;
    padding: 5vh;
    padding-left: 10vh;
    padding-right: 10vh;
}

.speaker {
    font-family: 'comfortaa';
    font-size: 3vh;
    font-style: italic;
    text-align: center;
}

.quote {
    text-align: center;
    font-family: 'quicksand';
    font-size: 3vh;
}

@media only screen and (max-width: 900px) {
    .wrap {
        margin: 1vh;
        width: 90%;
    }
}