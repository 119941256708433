.books {
    display: flex;
    justify-content: space-around;
}

@media only screen and (max-width: 800px) {
    .books {
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
    }
}