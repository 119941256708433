.root {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.331);
}

.list {
    overflow-y: scroll;
    width: 70vh;
    height: 55vh;
    background-color: rgba(0, 0, 0, 0.753);
    border-radius: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 5vh;
    font-family: 'quicksand';
}

.button {
    text-decoration: none;
    color: rgb(130, 0, 0);
    font-family: 'proza libre';
    font-size: 3vh;
    padding: 1.5vh;
    border: 1px solid rgb(76, 0, 0);
    background-color: rgb(255, 255, 255);
    margin: 1vh;
    border-radius: 5vh;
    width: 70%;
    margin-bottom: 3vh;
}

.button:hover {
    animation: hover .3s ease;
    background-color: rgb(130, 0, 0);
    color: white;
    border-color: white;
}

.back-wrap {
    width: 70%;
    display: flex;
    justify-content: center;
    margin: 3vh;
}

@keyframes hover {
    from {
        background-color: white;
        color: rgb(130, 0, 0);
        border-color: rgb(76, 0, 0);
    }

    to {
        background-color: rgb(130, 0, 0);
        color: white;
        border-color: white;
    }
}

@media only screen and (max-width: 600px) {
    .root {
        overflow-x: hidden;
    }

    .list {
        width: 100%;
        overflow-x: hidden;
    }
}