.title {
    color: white;
    text-align: center;
    font-size: 10vh;
    font-family: 'lato';
    background-image: url('./../../pictures/bg.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 80vh;
    padding: 2vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    overflow-y: hidden;
}

.carousel {
    width: 50%;
    display: flex;
    justify-content: center;
}

.carousel video {
    /* width: 100%; */
    height: 50vh;
    border-radius: 4vh;
}

.carousel video source {
    border-radius: 5vh;
}

.root {
    overflow: hidden;
    scroll-snap-align: center;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.quote-section {
    background-color: rgb(102, 0, 0);
    color: white;
    padding: 5vh;
}

.section-one {
    display: flex;
    align-items: center;
}

.button {
    background-color: inherit;
    color: white;
    border: none;
    font-size: 4vh;
    font-family: 'Quicksand';
}

.under-title {
    font-size: 4vh;
    font-family: 'Montserrat';
    /* transform: translateY(-5vh); */
}

@media only screen and (max-width: 900px) {
    .row {
        flex-direction: column;
        
    }

    .carousel {
        display: none;
    }

    .quote-section {
        padding: 0;
        padding-top: 2vh;
        width: 100%;
        overflow-x: hidden;
    }

    .button {
        margin: 3vh;
    }

    .title {
        height: fit-content;
    }
}