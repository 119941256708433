.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 28%;
}

.subtitle {
    font-family: 'quicksand';
    font-size: 2.1vh;
    text-align: center;
}

.data li {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: space-between;
    padding: 1vh;
}

.data li img {
    width: 10%;
    padding-right: 1vh;
}

.item {
    border: 1px solid black;
    width: 70%;
    margin: 5vh;
    border-radius: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    overflow: hidden;
}

a {
    text-decoration: none;
    color: black;
}

.pic img {
    height: 100%;
    border-bottom: 1px solid black;
}

.pic {
    height: 40vh
}

.data {
    text-align: left;
    margin-left: 3vh;
    margin-right: 3vh;
    font-family: 'nunito';
    font-size: 2.5vh;
    padding: 2vh;
    padding-bottom: 4vh;
    /* margin-top: -6vh; */
    /* transform: translateX(-5%); */
}

.book {
    text-decoration: none;
    color: rgb(142, 0, 0);
    font-family: 'proza libre';
    font-size: 3vh;
    padding: 1.5vh;
    padding-left: 3vh;
    padding-right: 3vh;
    border: 1px solid rgb(76, 0, 0);
    background-color: rgba(255, 255, 255, 0.742);
    margin: 1vh;
    border-radius: 5vh;
    /* width: 60%; */
}

.price {
    text-align: center;
    font-family: 'lato';
    font-size: 5vh;
}

.line {
    width: 70%;
}

.four-classes {
    font-family: 'quicksand';
    font-size: 2vh;
}

.info__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.info {
    border: 1px solid black;
    padding: 1vh;
    padding-right: 2vh;
    padding-left: 2vh;
    border-radius: 5vh;
    font-size: 2.4vh;
}

.info:hover {
    animation: hover .2s ease-in-out;
    background-color: black;
    color: white;
}

.info:hover ~ .info__text {
    display: block;
    animation: slide-in .3s ease-in;
    transform: translateX(0vh);
}

.info__text {
    display: none;
    position: absolute;
    /* bottom: 4vh; */
    right: 40vh;
    border-radius: 4vh;
    background-color: rgb(255, 255, 255);
    color: black;
    padding: 5vh;
    border: 1px solid black;
    width: 20vh;
    text-align: center;
    font-family: 'comfortaa';
}

@keyframes hover {
    from {
        background-color: white;
        color: black;
    }

    to {
        background-color: black;
        color: white;
    }
}

@keyframes mobile-slide-in {
    from {
        transform: translate(90%, 70vh);
        opacity: 0;
    }

    to {
        transform: translate(90%, 40vh);
        opacity: 1;
    }
}

@keyframes slide-in {
    from {
        transform: translateY(20vh);
        opacity: 0;
    }

    to {
        transform: translateY(0vh);
        opacity: 1;
    }
}

@media only screen and (max-width: 900px) {
    .wrap {
        width: 100%;
    }

    .item {
        width: 95%;
    }
    
    .info:hover ~ .info__text {
        display: block;
        animation: mobile-slide-in .3s ease-in-out;
        transform: translate(90%, 40vh);
    }

    .pic {
        height: fit-content;
        width: fit-content;
    }

    .pic img {
        width: 100%;
    }

    .data {
        
    }
}