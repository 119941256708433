.button {
    width: 90%;
    padding: 2vh;
    margin: 4vh;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 5vh;
    font-size: 3vh;
    font-family: 'Avenir';
    text-align: center  ;
}

.root {
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
}

.button:hover {
    animation: hover .4s ease-in-out;
    background-color: rgb(92, 0, 0);
    color: white;
    border-color: white;
}

@keyframes hover {
    0% {
        background-color: transparent;
    }

    100% {
        background-color: rgb(92, 0, 0);
        color: white;
        border-color: white;
    }
}