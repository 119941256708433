.root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.modal {
    border-radius: 3vh;
    height: 80vh;
    width: 80vh;
    padding: 5vh;
    background-color: whitesmoke;
    z-index: 100;
    overflow-y: hidden;
    overflow-y: scroll;
}

.list {
    display: flex;
    flex-direction: column;
}

.total {
    font-family: 'Lato';
    font-size: 5vh;
    display: flex;
    justify-content: space-between;
    margin: 3vh;
}

.close {
    position: absolute;
    background-color: inherit;
    border: none;
}

@media only screen and (max-width: 800px) {
    .modal {
        width: 100%;
    }

    .total {
        font-size: 4vh;
        white-space: no-wrap;
    }
}