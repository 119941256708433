.classes {
    display: flex;
    justify-content: center;
}

.wrap {
    display: flex;
    justify-content: center;
}

.root {
    overflow-x: hidden;
}

@media only screen and (max-width: 900px) {
    .classes {
        flex-direction: column;
    }
}