.header {
  padding: 2vh;
  background-color: rgb(102, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-header {
  display: none;
}

.logo {
  display: flex;
  align-items: center;
  height: 15vh;
}

.logo img {
  height: 80%;
}

.header-text {
  margin-left: 5vh;
  color: white;
  font-size: 4vh;
  font-family: 'Montserrat';
}

nav {
  display: flex;
  align-items: center;
  margin-right: 5vh;
  font-family: 'Proza Libre';
  font-size: 2.5vh;
}

.nav-item {
  padding: 2vh;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  display: inline;
  position: relative;
}

.nav-item:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.nav-item:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.subnav-content {
  z-index: 100;
  display: none;
}

.subnav {
  display: flex;
  justify-content: center;
}

.subnav:hover > .subnav-content {
  animation: dropdown 0.3s ease-out;
  position: absolute;
  top: 12.8vh;
  display: flex;
  flex-direction: column;
}

.subnav-item {
  color: white;
  background-color: rgba(125, 22, 22, 0.424);
  padding: 2vh;
  text-decoration: none;
  margin: 0.3vh;
  border-radius: 5px;
  border: 1px solid white;
  text-align: center;
}

.subnav-item:hover {
  animation: subnav-hover .3s ease-in-out;
  background-color: rgb(122, 0, 0);
}

.subnav p {
  cursor: pointer;
}

.sign-in {
  text-decoration: none;
  background-color: white;
  color: black;
  padding: 1.5vh;
  padding-left: 3vh;
  padding-right: 3vh;
  border-radius: 30px;
  border: 1px solid black;
  margin-left: 3vh;
  font-size: 2.5vh;
}

.sign-in:hover {
  animation: sign-in-hover .3s ease-in;
  background-color: black;
  border-color: white;
  color: white;
}

.cart {
  height: 8vh;
  background-color: inherit;
  border: none;
  margin-left: 3vh;
  background-color: white;
  border-radius: 10vh;
  padding-left: 2vh;
  padding-right: 2vh;
}

.cart:hover {
  animation: cart-hover .3s ease-out;
  opacity: .6;
}

.cart img {
  height: 50%;
  border-radius: 0vh;
}

.more {
  background-color: inherit;
  border: none;
  position: absolute;
  left: 0;
}

.in-nav-more {
  background-color: inherit;
  border: none;
}

.centered {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .cart-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
} */

.cart:hover {
  cursor: pointer;
}

.quantity {
  position: absolute;
  top: 0;
  right: 4vh;
  float: right;
  padding: 1vh;
  border-radius: 3vh;
  margin: 0;
}

.quantity p {
  background-color: rgb(206, 1, 1);
  padding: 1vh;
  padding-left: 2vh;
  padding-right: 2vh;
  border-radius: 7vh;
  color: white;
}

.quantity p:hover {
  cursor: pointer;
}

@keyframes dropdown {
  from {
    transform: translateX(10vh);
  }
  to {
    transform: translateX(0vh);
  }
}

@keyframes hover {
  from {
    color: white;
    background-color: inherit;
  }
  to {
    text-decoration: underline;
  }
}

@keyframes subnav-hover {
    from {
      background-color: rgba(125, 22, 22, 0.573);
    }
    to {
      background-color: rgb(122, 0, 0);
    }
}

@keyframes sign-in-hover {
  from {
    background-color: white;
    border-color: black;
    color: black;
  }
  to {
    background-color: black;
    border-color: white;
    color: white;
  }
}

@keyframes cart-hover {
  from {
    opacity: 1;
  }
  to {
    opacity: .6;
  }
}

@media only screen and (max-width: 1100px) {
  .header-text {
    display: none;
  }
}

@media only screen and (max-width: 845px) {
  .header {
    display: none;
  }

  .mobile-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2vh;
    background-color: rgb(102, 0, 0);
  }

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: black;
    padding-bottom: 10vh;

  }

  .mobile-nav {
    padding-top: 5.3vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: inherit;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgb(102, 0, 0);
    overflow: hidden;
  }

  .nav-item {
    margin-left: 2vh;
  }

  .sign-in {
    margin-left: 0;
  }

  .mobile-logout {
    margin-left: 2vh;
    padding: 2vh;
    color: white;
    font-family: 'Proza Libre';
    font-size: 2.5vh;
    background-color: inherit;
    border: none;
  }

  .quantity {
    position: absolute;
    top: 7vh;
    right: 1vh;
  }

  .centered {
    transform: translateX(-1vh);
  }

  .cart {
    position: absolute;
    right: 5vh;
    z-index: -1;
  }
  
  .mobile-drawer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}