.item {
    border-radius: 2vh;
    /* width: 30vh; */
    height: 50vh;
    margin: 5vh;
    margin-bottom: 10vh;
}

.item img {
    border: 2px solid black;
    height: 100%;
    border-radius: 2vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    color: white;
}

.item:hover {
    animation: hover .3s ease-in;
    opacity: .7;
    color: black;
}

.title {
    text-align: center;
    font-family: 'montserrat';
    font-size: 6vh;
}

.title1 {
    display: none;
}

@keyframes hover {
    from {
        opacity: 1;
        color: white;
    }
    to {
        opacity: .7;
        color: black;
    }
}

@media only screen and (max-width: 900px) {
    .item {
        margin-bottom: 8vh;
        width: fit-content;
        color: white;
        font-weight: bold;
    }

    .title {
        display: none;
    }
    
    .title1 {
        display: block;
        text-align: center;
        font-family: 'Lato';
        font-size: 5vh;
        position: relative;
        top: 15vh;
    }
}