form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contact {
    width: 40%;
    margin: 5vh;
}

.wrap {
    display: flex;
    justify-content: center;
    width: 100%;
}

.small-form {
    width: 60%;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.invalid {
    background-color: rgba(185, 0, 0, 0.594);
}

.label {
    text-align: left;
}

label {
    font-size: 3vh;
    font-family: 'montserrat';
}

input {
    background-color: white;
    border: none;
    border: 1px solid black;
    padding: 1.5vh;
    margin: 2vh;
    border-radius: 20px;
}

textarea {
    height: 20vh;
    border-radius: 20px;
    border: 1px solid black;
    padding: 2vh;
    margin: 2vh;
    margin-left: 2vh;
    margin-right: 2vh;
}

.submit {
    padding: 1.5vh;
    padding-left: 2.5vh;
    padding-right: 2.5vh;
    background-color: white;
    border-radius: 30px;
    border: 1px solid black;
    font-size: 2.3vh;
    font-family: 'Proza Libre';
    margin: 4vh;
    color: black;
}

.submit:hover {
    animation: hover .3s ease-in;
    background-color: black;
    border-color: white;
    color: white;
}

@keyframes hover {
    from {
        background-color: white;
        border-color: black;
        color: black;
    }

    to {
        background-color: black;
        border-color: white;
        color: white;
    }
}

@media only screen and (max-width: 800px) {
    .contact {
        width: 100%;
    }
}