.root {
    margin: 10vh;
}

.teachers {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 900px) {
    .root {
        margin: 0;
        margin-bottom: 5vh;
    }

    .teachers {
        flex-direction: column;
        align-items: center;
    }
}