.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.351);
    z-index: 10;
}

.subtitle {
    font-size: 4vh;
    text-align: center;
    font-family: 'montserrat';
}

.error {
    color: red;
    font-size: 4vh;
    text-align: center;
    font-family: 'montserrat';
}

.wrap {
    display: flex;
    justify-content: center;
}

.half-width-block {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
}

.pg {
    font-size: 2.4vh;
    line-height: 5vh;
    margin-left: 4vh;
    margin-right: 4vh;
    text-align: center;
}

.row {
    display: flex;
}

.title {
    text-align: center;
    font-size: 10vh;
    font-family: 'lato';
    margin: 2vh;
}

.book {
    text-decoration: none;
    color: white;
    font-family: 'proza libre';
    font-size: 3vh;
    padding: 2vh;
    border: 1px solid white;
    background-color: rgba(152, 0, 0, 0.359);
    transform: translateY(-10vh);
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 800px) {
    .half-width-block {
        width: 100%;
    }

    .wrap {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .subtitle {
        font-size: 3.3vh;
    }

    /* .title {
        font-size: 5vh;
    } */
}