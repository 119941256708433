footer {
    padding: .5vh;
    background-color: rgb(92, 0, 0);
    color: white;
    text-align: center;
}

.footer-item {
    margin: 3vh;
}

.小红书 {
    margin: 3vh;
    overflow: hidden;
    width: 10vh;
    height: 10vh;
    /* border-radius: 5vh; */
}

.socials {
    display: flex;
    justify-content: center;
}

.foot-note {
    color: white;
    font-family: 'Proza Libre';
    font-size: 3vh;
}

.foot-note a {
    color: white;
}