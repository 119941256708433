.wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 5vh;
    /* background-color: rgb(75, 0, 0); */
}

.pg {
    width: 70%;
    /* color: white; */
    font-family: 'avenir';
}

.pic {
    width: 30%;
    margin-left: 2vh;
    padding-bottom: 4vh;
}

.pic img {
    width: 100%;
    float: left;
    border-radius: 3vh;
    border: 2px solid rgb(124, 0, 0);
}

.text {
    font-family: 'quicksand';
    font-size: 2.6vh;
    line-height: 5vh;
    margin-left: 8vh;
    margin-right: 8vh;
    text-align: center;
}

/* p img {
    width: 14%;
    float: left;
    margin-right: 3vh;
    margin-bottom: 3vh;
} */

@media only screen and (max-width: 900px) {
    .wrap {
        flex-direction: column;
    }

    .pic {
        width: 80%;
        float: none;
        padding: 0;
        margin: 0;
    }

    .pg {
        width: 100%;
    }

    .pic {
        order: 0;
    }
}